import React from 'react'
import { Link } from 'gatsby'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Namaste God <em>.</em><em>.</em><em>.</em> <span><i>Puja</i> is now just a <i>Click</i> Away</span></h1>
            </header>
            <div className="content">
                <p>NamasteGod provides the best Pandit for puja in Bangalore.
                All our <a href="https://en.wikipedia.org/wiki/Purohit" class="no-highlight">pandits</a> are personally verified with over 10 years of experience in Puja path, vidi vidhan.
                The Best Pandit in Bangalore is now just a Click Away!</p>
                <ul className="actions">
                    <li><Link to="/pandit-in-bangalore-city-by-your-area" className="button next scrolly">Browse</Link></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
