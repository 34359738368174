import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

const Index = ({data}) => {
    return  <Layout>
                <Helmet
                    title="Pandit for Puja in Bangalore (15+ YRS EXP): Book Pandit in Bangalore Online"
                    meta={[
                        { name: "description", content: "Planning to book a Pandit for puja in Bangalore? Then You are at the right destination! We provide the best Pandit in Bangalore online service with 15+ years of experience for all kinds of pujas. Puja is now just a click away! Book with us!" },
                        { name: "keywords", content: "Pandit for Puja in Bangalore, Pandit in Bangalore" },
                    ]}
                    htmlAttributes={{
                        lang: "en"
                    }}
                >
                    <script type="application/ld+json">{`
                    {
                      "@context": "http://schema.org",
                      "@type": "Service",
                      "serviceType": "Pandit for Puja in Bangalore",
                      "provider": {
                        "@type": "LocalBusiness",
                        "name": "NamasteGod",
                        "telephone": "(+91) 81972 49957",
                        "priceRange": "₹₹₹",
                        "url": "https://www.namastegod.com",
                        "image": [
                          "https://lh3.googleusercontent.com/lQDjg_K0aUpumCyAhniS93kJwHjTgmESI-Fd_u7P2WISIfDFWww5_V44jQXtoUrcoJsnxSiov273vxVF1g=s239-c",
                          "https://lh3.googleusercontent.com/Wc2BcQW4pReeAGSHOnFuSd_s9LP2mUYXt4aBitv17QFDvSM8a4ExSCf3rir49xXvkJegs4uPU94_Jgad=s239-c",
                          "https://lh4.googleusercontent.com/-EXCsRc5tnmc/AAAAAAAAAAI/AAAAAAAAAAA/u-HcCeFyTic/s44-p-k-no-ns-nd/photo.jpg"
                         ],
                        "address":
                        {
                          "@type": "PostalAddress",
                          "addressLocality": "Bangalore",
                          "addressRegion": "KA",
                          "addressCountry": "IN",
                          "postalCode": "560078",
                          "streetAddress": "#142/1, 17th Cross, 18th Main, JP Nagar 5th Phase"
                         },
                         "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": 12.9074399,
                            "longitude": 77.5617965
                          },
                           "openingHoursSpecification": [
                            {
                              "@type": "OpeningHoursSpecification",
                              "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                                "Sunday"
                              ],
                              "opens": "08:00",
                              "closes": "22:30"
                            }
                          ]
                      },
                      "areaServed": [
                        {
                          "@type": "City",
                          "name": "Bangalore",
                          "@id": "https://en.wikipedia.org/wiki/Bangalore"
                        }
                      ]
                    }
                `}</script>
                <script type="application/ld+json">{`
                    {
                      "@context": "http://schema.org",
                      "@type": "WebSite",
                      "url": "https://www.namastegod.com"
                    }
                `}</script>
                <script type="application/ld+json">{`
                    {
                      "@context": "http://schema.org",
                      "@type": "Organization",
                      "name": "NamasteGod",
                      "url": "https://www.namastegod.com",
                      "sameAs": [ 
                          "https://twitter.com/namastegodindia",
                          "https://www.facebook.com/namastegodindia",
                          "https://www.instagram.com/namastegod.india",
                          "https://www.youtube.com/channel/UCSRchwHYLnyojdzp1BvYW6w",
                          "https://in.linkedin.com/company/namaste-god"
                       ]
                    }
                `}</script>
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${data.allFile.nodes[0].childImageSharp.fluid.src})`}}>
                            <header className="major">
                                <h3>{data.allFile.nodes[0].name}</h3>
                            </header>
                            <Link to="/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-ganesh-ganapathi-homam-services-at-home-in-bangalore-india" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${data.allFile.nodes[1].childImageSharp.fluid.src})`}}>
                            <header className="major">
                                <h3>{data.allFile.nodes[1].name}</h3>
                            </header>
                            <Link to="/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-house-warming-ceremony-gruhapravesam-in-bangalore" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${data.allFile.nodes[2].childImageSharp.fluid.src})`}}>
                            <header className="major">
                                <h3>{data.allFile.nodes[2].name}</h3>
                            </header>
                            <Link to="/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/hindu-wedding-priest-or-pandit-for-marriage-in-bangalore-india" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${data.allFile.nodes[3].childImageSharp.fluid.src})`}}>
                            <header className="major">
                                <h3>{data.allFile.nodes[3].name}</h3>
                            </header>
                            <Link to="/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-satyanarayana-swamy-vratam-mahapooja-online-at-home-in-bangalore-india" className="link primary"></Link>
                        </article>
                    </section>
                </div>
            </Layout>
}

export const query = graphql`
{
    allFile(filter: {name: {in: ["Griha Pravesh", "Satyanarayana Puja", "Marriage", "Ganapathi Homam"]}}, sort: {fields: name}) {
        nodes {
            name
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
}`

export default Index